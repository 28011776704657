import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

export default class DemoForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }

  render() {
    const { status } = this.state
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/myywprro"
        method="POST"
        name="kodiak-demo-form"
      >
        <input type="text" name="first-name" placeholder="First Name*" />
        <br />
        <input type="text" name="last-name" placeholder="Last Name*" />
        <br />
        <input type="text" name="job-title" placeholder="Job Title" />
        <br />
        <input type="text" name="city" placeholder="City*" />
        <br />
        <input type="text" name="state" placeholder="State/Region*" />
        <br />
        <input type="zip" name="zip" placeholder="Zip*" />
        <br />
        <input type="text" name="email" placeholder="Email*" />
        <br />
        <input type="tel" name="phone" placeholder="Phone number*" />

        <textarea
          style={{ width: "100%" }}
          name="message"
          placeholder="Message"
        />

        {status === "SUCCESS" ? <p>Thanks!</p> : <button>Submit</button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </form>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}
