import styled from "styled-components"

export const DemoPageStyles = styled.div`
  #demo-container {
    width: 350px;
    margin-right: auto;
    margin-left: auto;
  }

  #demo-form {
    /* width: 350px; */
    padding: 2rem 0;
    /* margin-right: auto;
    margin-left: auto; */

    .input-bottom-boxes {
      width: 100%;
    }
    input {
      width: 100%;
      margin: 10px 0;
      padding: 5px 0;
    }
    button {
      width: 100%;
      margin: 10px 0;
      padding: 10px 0;
      background-color: #333;
      color: white;
      border: none;
    }
  }
`
