import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"
import { DemoPageStyles } from "../components/styles/DemoPageStyles"
import DemoForm from "../components/DemoForm"

const DemoPage = () => {
  return (
    <MainLayout>
      <PageHeading
        pageHeading="Schedule a Demo"
        // description="Let us help you cray direct trade  readymade narwhal YOLO cornhole Kickstarter kitsch wayfarers artisan synth Vice seitan"
      />
      <DemoPageStyles>
        <div id="demo-container">
          <div id="demo-form">
            <DemoForm />
          </div>
        </div>
      </DemoPageStyles>
    </MainLayout>
  )
}

export default DemoPage
